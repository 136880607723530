<template>
	<form action="">
		<div class="modal-card" style="width: 600px">
			<header class="modal-card-head has-background-success">
				<p class="modal-card-title has-text-white">Terminar venta</p>
				<button
					type="button"
					class="delete"
					@click="$emit('close', 'venta')"/>
			</header>
			<section class="has-text-centered modal-card-body">
				<p class="is-size-1 has-text-weight-bold">Total venta</p>
				<div class="columns">
				<p class="column is-half is-size-1 has-text-weight-bold">Bs {{ totalVenta }}</p>
				<p class="column is-half is-size-1 has-text-weight-bold">$ {{ totalVenta2 }}</p>
				</div>
				<busqueda-cliente @seleccionado="onSeleccionado" />
				<br>
				<div class="columns">
				<b-field class=" has-text-centered" label="El cliente paga con Bolivares" >
					<b-input step="any" ref="pagado" icon="currency-usd" type="number"  placeholder="Pagado en Bolivares" v-model="pagado" @input="calcularCambio" @keyup.enter.native="terminarVenta" size="is-medium"></b-input>
				</b-field>
				<b-field class=" has-text-centered" label="El cliente paga con Dolares" >
					<b-input step="any" ref="pagado2" icon="currency-usd" type="number" placeholder="Pagado en Dolares" v-model="pagado2" @input="calcularCambio2" @keyup.enter.native="terminarVenta" size="is-medium"></b-input>
				</b-field>
				</div>
				<div class="columns">
				<p class="column is-half is-size-1 has-text-weight-bold">Cambio Bs {{ (cambio).toFixed(2) }}</p>
				<p class="column is-half is-size-1 has-text-weight-bold">Cambio $ {{ (cambio2).toFixed(2) }}</p>
				</div>
				<b-field class=" has-text-centered" label="Vuelto en Bolivares" >
					<b-input step="any" ref="pagado3" icon="currency-usd" type="number" placeholder="Dolares" v-model="pagado3" @input="calcularCambio3" @keyup.enter.native="terminarVenta" size="is-medium"></b-input>
				</b-field>
				<p class=" is-size-1 has-text-weight-bold">Vuelto Bs {{ (cambio3).toFixed(2) }}</p>

			</section>
			<footer class="modal-card-foot">
				<b-button
					label="Cancelar"
					icon-left="cancel"
					size="is-medium"
					@click="$emit('close', 'venta')" />
				<b-button
					label="Terminar venta"
					type="is-success"
					icon-left="check"
					size="is-medium"
					@click="terminarVenta" />
			</footer>
		</div>
	</form>
</template>
<script>
	import BusquedaCliente from '../Clientes/BusquedaCliente'

	export default{
		name:"DialogoTerminarVenta",
		props: ['totalVenta','totalVenta2'],
		components: { BusquedaCliente },

		data:()=>({
			pagado: "",
			pagado2: "",
			pagado3: "",
			cambio: 0,
			cambio2: 0,
			cambio3: 0,
			cliente: {},
			tasaDeCambio: localStorage.getItem('tasaDeCambio') || 0
		}),

		mounted(){
			this.$refs.pagado.focus()
		},

		methods:{
			onSeleccionado(cliente){
				this.cliente = cliente
			},

			calcularCambio(){
				this.cambio = parseFloat(this.pagado-this.totalVenta)
			},

			calcularCambio2(){
				this.cambio2 = parseFloat(this.pagado2-this.totalVenta2)
			},
			calcularCambio3(){
				this.cambio3 =parseFloat(this.pagado3*this.tasaDeCambio)
			},
			terminarVenta(){
				this.pagado = parseFloat(this.pagado).toFixed(2);
				if(this.pagado === "") {
					this.$buefy.toast.open({
                         type: 'is-danger',
                         message: 'Debes colocar el total pagado.'

                    })
                    return
				}

				let payload = {
					tipo: 'venta',
					pagado: this.pagado,
					cambio: this.cambio,
					cliente: this.cliente
				}

				this.$emit("terminar", payload)
			}	
		}
	}
</script>