<template>
    <form>
        <b-field label="Nombre del negocio" >
            <b-input step="any" icon="store" type="text" placeholder="Ej. Supermercado" v-model="datosNegocio.nombre"></b-input>
        </b-field>
        <b-field label="Teléfono" >
            <b-input step="any" icon="phone" type="number" placeholder="Ej. 0412567544" v-model="datosNegocio.telefono"></b-input>
        </b-field>
        <b-field label="Dolar" >
            <b-input step="any" type="number" placeholder="Ej. 36.56" v-model="datosNegocio.dolar"></b-input>
        </b-field>

    <img src=http://localhost/pos/api/logos/logo.png width="150" height="200">
    <div class="buttons has-text-centered">
        <b-button v-if="idUser === '1'" type="is-primary" size="is-large" icon-left="check" @click="registrar">Registrar</b-button>
        <b-button type="is-dark" size="is-large" icon-left="cancel" tag="router-link" to="/">Cancelar</b-button>
    </div>

    <errores-component :errores="mensajesError" v-if="mensajesError.length > 0" />
</form>
</template>
<script>
    import Utiles from '../../Servicios/Utiles'
    import ErroresComponent from '../Extras/ErroresComponent'
    import AyudanteSesion from '../../Servicios/AyudanteSesion'

    export default{
        name: "FormConfiguracion",
        props: ["datos"],
        components: { ErroresComponent },

        data:()=>({
            datosNegocio: {
                nombre: "",
                telefono: "",
                 dolar: "",
            },
            urlImagen: null,
            mensajesError: []
        }),

        mounted(){
            this.datosNegocio = this.datos
            this.urlImagen = Utiles.regresarRuta() + this.datos.logo
            this.idUser = AyudanteSesion.obtenerDatosSesion().id
        },

        methods: {
            onFileChange() {
              let input = this.$refs.fileInput
              let file = input.files
              if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.urlImagen = e.target.result
                }
                reader.readAsDataURL(file[0])
                this.$emit('input', file[0])
            }
        },

        registrar(){

            this.mensajesError = Utiles.validarDatos(this.datosNegocio)
            if(this.mensajesError.length > 0) return
            let cambiaLogo = (this.urlImagen.includes("data:image")) 
            this.datosNegocio.logo = (cambiaLogo) ? this.urlImagen : this.urlImagen.split('api')[1]
            this.datosNegocio.cambiaLogo = cambiaLogo
            this.$emit("registrar", this.datosNegocio)
            //GUARDAR EL VALOR DE EL DOLAR
            localStorage.setItem('tasaDeCambio', this.datosNegocio.dolar);
            this.$emit('guardarTasaDeCambio', this.datosNegocio.dolar);
        }
    }


}
</script>