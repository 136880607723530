<template>
    <section class="hero  is-fullheight fondo">

        <div class="hero-body">
            <div class="container">

                <div class="box" >
                     <h1 class="title is-1 has-text-centered is-family-monospace">Sistema de ventas</h1>
                    <img src="http://localhost/pos/api/logos/logo2.png" width="200" style="display: block; margin:0 auto;">
                    <b-field label="Nombre de usuario" >
                        <b-input  icon="shield-account" type="text" placeholder="Ej. Manuel Valles" v-model="usuario.usuario"></b-input>
                    </b-field>
                    <b-field label="Contraseña" >
                        <b-input type="password"
                            placeholder="Contraseña"
                            v-model="usuario.password"
                            password-reveal>
                        </b-input>
                    </b-field>
                    <b-button type="is-primary" size="is-large" icon-left="check" @click="iniciarSesion">Ingresar</b-button>
                </div>
                <div v-if="informacionNegocio" class="has-text-centered">
                    <h2 class="title is-2 has-text-danger-dark has-text-weight-bold">{{ informacionNegocio.nombre }}</h2>
                    <h3 class="title is-3 has-text-info-dark has-text-weight-bold">Teléfono: {{ informacionNegocio.telefono }}</h3>
                </div>
                
            </div>
      </div>
    </section>
</template>
<script>
    import HttpService from '../../Servicios/HttpService'
    import AyudanteSesion from '../../Servicios/AyudanteSesion'

    export default {
        name: "InicioSesion",
        
        data:()=>({
            usuario: {
                usuario: "",
                password: ""
            },

            informacionNegocio: null
        }),

        mounted(){
            this.obtenerInformacionLocal()
            this.informacionNegocio = AyudanteSesion.obtenerDatosNegocio()
        },

        methods:{
            iniciarSesion(){
                let payload = {
                    accion: 'iniciar_sesion',
                    usuario: this.usuario
                }

                HttpService.obtenerConConsultas('usuarios.php', payload)
                .then(resultado => {
                    if(!resultado){
                        this.$buefy.toast.open({
                          type: 'is-danger',
                          message: 'Información incorrecta. Verifica.'
                        })
                        return
                    }

                    if(resultado.estado){
                        this.$buefy.toast.open({
                          type: 'is-info',
                          message: 'Información correcta. Bienvenido.'
                        })
                        this.$router.push({ name: 'InicioComponent' })
                        this.$emit("login", resultado)
                    }
                })
            },

            obtenerInformacionLocal(){
                HttpService.obtenerConConsultas('ajustes.php',{
                    accion: 'obtener'
                })
                .then(resultado =>{
                    AyudanteSesion.establecerDatosNegocio(resultado)
                })
            }
        }
        
    }
</script>
<style>
    .fondo{
        background-color:  #8aa5f5;
        background-image: url("http://localhost/pos/api/logos/fondo.png");
    background-attachment: fixed;
    background-size: cover;
    }
</style>